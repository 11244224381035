import {Box, Container, Grid} from "@mui/material";
import Layout from "../../components/layout/layout";
import {useSelector} from "react-redux";
import {selectProduct} from "../../redux/products/product-reducer";
import Product from "../../components/shared/product";

const ProductsPage = () => {

    const {products} = useSelector(selectProduct);

    return (
        <Layout>
            <Box sx={{py: 12, backgroundColor: 'background.default', minHeight: '100vh'}}>
                <Container>
                    <Grid container={true} spacing={2}>
                        {products && products.map(product => {
                            return (
                                <Grid key={product._id} xs={12} md={4} lg={3} item={true}>
                                    <Product elevation={0} variant='container' product={product}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
        </Layout>
    )
}

export default ProductsPage;
