import nike from './../../assets/images/nike.png';
import casablanca from './../../assets/images/casablanca.png';
import adidas from './../../assets/images/adidas.png';
import puma from './../../assets/images/puma.png';
import reebok from './../../assets/images/reebok.png';
import burberry from './../../assets/images/burberry.png';

export const brands = [
    {
        name: 'Nike',
        image: nike
    },
    {
        name: 'Casablanca',
        image: casablanca
    },
    {
        name: 'Adidas',
        image: adidas
    },
    {
        name: 'Puma',
        image: puma
    },
    {
        name: 'Reebok',
        image: reebok
    },
    {
        name: 'Burberry',
        image: burberry
    }
]
