import {Box, Stack, Typography, useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";

const GalleryImage = ({galleryImage}) => {

    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'relative',
                height: '50vh',
                cursor: 'pointer',
                borderColor: theme.palette.mode === 'light' ? grey[100] : grey[800],
            }}>

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}>
                <img
                    alt={galleryImage.name}
                    src={galleryImage.image}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectPosition: 'center',
                        objectFit: 'cover'
                    }}
                />
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    padding: 2,
                    color: 'rgba(255, 255, 255, 0)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)',
                        color: 'rgba(255, 255, 255, 0.85)',
                    }
                }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{height: '100%'}}>
                    <Box>
                        <Typography
                            mb={2}
                            sx={{color: 'white'}}
                            align="center"
                            variant="h5">
                            {galleryImage.name}
                        </Typography>
                        <Stack direction="row" justifyContent="center">
                            <Typography
                                mb={2}
                                sx={{
                                    display: 'inline',
                                    backgroundColor: 'light.secondary',
                                    borderRadius: 1.4,
                                    color: 'secondary.main',
                                    padding: 1
                                }}
                                align="center"
                                variant="body1">
                                {galleryImage.brand}
                            </Typography>
                        </Stack>
                        <Typography
                            mb={2}
                            sx={{
                                borderRadius: 1.4,
                                color: 'white',
                                padding: 1
                            }}
                            align="center"
                            variant="body2">
                            {galleryImage.description}
                        </Typography>
                        <Typography
                            sx={{color: 'white'}}
                            align="center"
                            variant="h5">
                            ${galleryImage.price}
                        </Typography>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default GalleryImage;
