import {ORDER_ACTION_TYPES} from "./order-action-types";

const INITIAL_STATE = {
    orders: [],
    orderLoading: false,
    orderError: null,
    orderMessage: null,
    totalOrders: 0,
    orderDetail: null
};


const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORDER_ACTION_TYPES.GET_ORDERS_REQUEST:
            return {
                ...state,
                orderError: null,
                orderMessage: null,
                orderLoading: true
            }

        case ORDER_ACTION_TYPES.GET_ORDERS_SUCCESS:
            return {
                ...state,
                orderError: null,
                orderMessage: null,
                orderLoading: true,
                orders: action.payload.data,
                totalOrders: action.payload.count
            }

        case ORDER_ACTION_TYPES.GET_ORDERS_FAIL:
            return {
                ...state,
                orderError: null,
                orderMessage: null,
                orderLoading: true
            }


        case ORDER_ACTION_TYPES.GET_ORDER_REQUEST:
            return {
                ...state,
                orderError: null,
                orderMessage: null,
                orderLoading: true,
                orderDetail: null
            }

        case ORDER_ACTION_TYPES.GET_ORDER_SUCCESS:
            return {
                ...state,
                orderError: null,
                orderMessage: null,
                orderLoading: true,
                orderDetail: action.payload.data,
            }

        case ORDER_ACTION_TYPES.GET_ORDER_FAIL:
            return {
                ...state,
                orderError: null,
                orderMessage: null,
                orderLoading: true
            }
        default:
            return state;
    }
}

export const selectOrder = state => state.order;

export default orderReducer;
