import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import UI_ACTION_CREATORS from "../../redux/ui/ui-action-creators";
import {Typography} from "@mui/material";

const NavLink = ({path, active, label}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        navigate(path);
        dispatch(UI_ACTION_CREATORS.changeURL(path));
        dispatch(UI_ACTION_CREATORS.closeDrawer());
    }

    return (
        <Link to={path} onClick={handleClick} style={{textDecoration: 'none'}}>
            <Typography
                variant="body1"
                sx={{
                    textTransform: 'capitalize',
                    color: active ? 'secondary.main' : 'text.secondary',
                }}>
                {label}
            </Typography>
        </Link>
    )
}

export default NavLink;
