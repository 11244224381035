import {Box, SwipeableDrawer} from "@mui/material";
import Header from "../headers/header";
import {useDispatch, useSelector} from "react-redux";
import {selectUI} from "../../redux/ui/ui-reducer";
import UI_ACTION_CREATORS from "../../redux/ui/ui-action-creators";
import MobileSideDrawer from "../drawers/mobile-side-drawer";

const Layout = ({children}) => {
    const {drawerOpen} = useSelector(selectUI);
    const dispatch = useDispatch();



    return (
        <Box>
            <Header/>
            <Box sx={{minHeight: '94.8vh', backgroundColor: 'background.default'}}>
                {children}
            </Box>
            <SwipeableDrawer
                elevation={0}
                onClose={() => dispatch(UI_ACTION_CREATORS.closeDrawer())}
                onOpen={() => dispatch(UI_ACTION_CREATORS.openDrawer())}
                open={drawerOpen}>
                <MobileSideDrawer/>
            </SwipeableDrawer>
        </Box>
    )
}

export default Layout;
