import {Box, Container, Stack, Toolbar, useTheme} from "@mui/material";
import ContactLink from "../../shared/contact-link";
import {Call, Facebook, Instagram, LocationOn, Mail, Twitter} from "@mui/icons-material";

const DesktopTobNavBar = () => {

    const theme = useTheme();

    return (
        <Toolbar variant="dense" sx={{backgroundColor: 'secondary.main'}}>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                    <Stack
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row">
                        <ContactLink
                            icon={
                                <Call
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'light.primary',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'secondary.main',
                                        borderRadius: 4,
                                        padding: 0.6,
                                        fontSize: 32,
                                    }}
                                />
                            }
                            link="tel:+233270048319"
                            value="+233270048319"
                        />

                        <ContactLink
                            icon={
                                <LocationOn
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'light.primary',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'secondary.main',
                                        borderRadius: 4,
                                        padding: 0.6,
                                        fontSize: 32,
                                    }}
                                />
                            }
                            link=""
                            value="Magic Trim, East Legon"
                        />

                        <ContactLink
                            icon={
                                <Mail
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'light.primary',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'secondary.main',
                                        borderRadius: 4,
                                        padding: 0.6,
                                        fontSize: 32,
                                    }}
                                />
                            }
                            link="mailto:dev.stanley.hayford@gmail.com"
                            value="dev.stanley.hayford@gmail.com"
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center">
                        <ContactLink
                            icon={
                                <Facebook
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'light.primary',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'secondary.main',
                                        borderRadius: 4,
                                        padding: 0.6,
                                        fontSize: 32,
                                    }}
                                />
                            }
                            link="https://facebook.com/usermaatre.setepenre"
                        />

                        <ContactLink
                            icon={
                                <Instagram
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'light.primary',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'secondary.main',
                                        borderRadius: 4,
                                        padding: 0.6,
                                        fontSize: 32,
                                    }}
                                />
                            }
                            link="https://instagram.com/inigolopez"
                        />

                        <ContactLink
                            icon={
                                <Twitter
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'light.primary',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'secondary.main',
                                        borderRadius: 4,
                                        padding: 0.6,
                                        fontSize: 32,
                                    }}
                                />
                            }
                            link="https://twitter.com/sahayford"
                        />

                    </Stack>
                </Box>
            </Container>
        </Toolbar>
    )
}

export default DesktopTobNavBar;
