import './App.css';
import {Route, Routes} from "react-router";
import {THEMES} from "./themes/themes";
import {CssBaseline, ThemeProvider} from "@mui/material";
import HomePage from "./pages/home/home-page";
import ContactPage from "./pages/contact/contact-page";
import AboutPage from "./pages/about/about-page";
import ChangePasswordPage from "./pages/account/change-password-page";
import ProfilePage from "./pages/account/profile-page";
import UpdateProfilePage from "./pages/account/update-profile-page";
import CheckoutPage from "./pages/checkout/checkout-page";
import GalleryPage from "./pages/gallery/gallery-page";
import OrderDetailPage from "./pages/orders/order-detail-page";
import OrdersPage from "./pages/orders/orders-page";
import PaymentMethodsPage from "./pages/payment-methods/payment-methods-page";
import ProductsPage from "./pages/products/products-page";
import ProductDetailPage from "./pages/products/product-detail-page";
import WishListsPage from "./pages/wishlist/wishlists-page";
import NotFoundPage from "./pages/not-found/not-found";
import SignInPage from "./pages/auth/sign-in-page";
import SignUpPage from "./pages/auth/sign-up-page";
import ResetPasswordPage from "./pages/auth/reset-password-page";
import ForgotPasswordPage from "./pages/auth/forgot-password-page";
import {useSelector} from "react-redux";
import {selectUI} from "./redux/ui/ui-reducer";

function App() {
    const {themeVariant} = useSelector(selectUI);

    return (
        <ThemeProvider theme={themeVariant === 'dark' ? THEMES.darkTheme : THEMES.lightTheme}>
            <CssBaseline enableColorScheme={true} />
            <Routes>
                <Route element={<HomePage/>} path="/" index={true} exact={true}/>
                <Route element={<ContactPage/>} path="/contact" exact={true}/>
                <Route element={<ChangePasswordPage/>} path="/change-password" exact={true}/>
                <Route element={<SignInPage/>} path="/auth/login" exact={true}/>
                <Route element={<SignUpPage/>} path="/auth/register" exact={true}/>
                <Route element={<ResetPasswordPage/>} path="/auth/reset-password" exact={true}/>
                <Route element={<ForgotPasswordPage/>} path="/auth/forgot-password" exact={true}/>
                <Route element={<AboutPage/>} path="/about" exact={true}/>
                <Route element={<ChangePasswordPage/>} path="/change-password" exact={true}/>
                <Route element={<ProfilePage/>} path="/profile" exact={true}/>
                <Route element={<UpdateProfilePage/>} path="/profile/update" exact={true}/>
                <Route element={<CheckoutPage/>} path="/checkout" exact={true}/>
                <Route element={<GalleryPage/>} path="/gallery" exact={true}/>
                <Route element={<OrderDetailPage/>} path="/orders/:orderID" exact={true}/>
                <Route element={<OrdersPage/>} path="/orders" exact={true}/>
                <Route element={<PaymentMethodsPage/>} path="/payments-methods" exact={true}/>
                <Route element={<ProductsPage/>} path="/products" exact={true}/>
                <Route element={<ProductDetailPage/>} path="/products/:productID" exact={true}/>
                <Route element={<WishListsPage/>} path="/wishlist" exact={true}/>
                <Route element={<NotFoundPage/>} path="*" exact={true}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
