import {FAQ_ACTION_TYPES} from "./faq-action-types";
import {faqs} from "./data";

const INITIAL_STATE = {
    faqs: [...faqs],
    faqLoading: false,
    faqError: null,
    faqMessage: null,
    totalFAQs: 0
};


const faqReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FAQ_ACTION_TYPES.GET_FAQS_REQUEST:
            return {
                ...state,
                faqError: null,
                faqMessage: null,
                faqLoading: true
            }

        case FAQ_ACTION_TYPES.GET_FAQS_SUCCESS:
            return {
                ...state,
                faqError: null,
                faqMessage: null,
                faqLoading: true,
                faqs: action.payload.data,
                totalFAQs: action.payload.count
            }

        case FAQ_ACTION_TYPES.GET_FAQS_FAIL:
            return {
                ...state,
                faqError: null,
                faqMessage: null,
                faqLoading: true
            }
        default:
            return state;
    }
}

export const selectFAQ = state => state.faq;

export default faqReducer;
