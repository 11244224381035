import {BRAND_ACTION_TYPES} from "./brand-action-types";
import {brands} from "./data";

const INITIAL_STATE = {
    brands: [...brands],
    brandLoading: false,
    brandError: null,
    brandMessage: null,
    totalBRANDs: 0
};


const brandReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BRAND_ACTION_TYPES.GET_BRANDS_REQUEST:
            return {
                ...state,
                brandError: null,
                brandMessage: null,
                brandLoading: true
            }

        case BRAND_ACTION_TYPES.GET_BRANDS_SUCCESS:
            return {
                ...state,
                brandError: null,
                brandMessage: null,
                brandLoading: true,
                brands: action.payload.data,
                totalBRANDs: action.payload.count
            }

        case BRAND_ACTION_TYPES.GET_BRANDS_FAIL:
            return {
                ...state,
                brandError: null,
                brandMessage: null,
                brandLoading: true
            }
        default:
            return state;
    }
}

export const selectBrand = state => state.brand;

export default brandReducer;
