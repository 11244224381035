import {
    Alert,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Container,
    Grid,
    LinearProgress,
    Rating,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import Layout from "../../components/layout/layout";
import {useSelector} from "react-redux";
import {selectProduct} from "../../redux/products/product-reducer";
import currencyFormatter from "currency-formatter";

const ProductDetailPage = () => {
    const {productLoading, productError, productDetail} = useSelector(selectProduct);

    return (
        <Layout>
            {productLoading && <LinearProgress variant="query" color="secondary"/>}
            <Container sx={{pt: 18, pb: 4}}>
                {productError && (
                    <Alert variant="standard" severity="error">
                        <AlertTitle>{productError}</AlertTitle>
                    </Alert>
                )}
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={5}>

                    </Grid>
                    <Grid item={true} xs={12} md={7}>
                        <Stack spacing={2} direction="column">
                            {productLoading ? <Skeleton variant="text"/> : (
                                <Typography variant="h5" sx={{color: 'text.primary'}}>
                                    {productDetail?.name}
                                </Typography>
                            )}
                            <Grid container={true} spacing={2} justifyContent="space-between">
                                <Grid item={true} xs={12} md="auto">
                                    <Typography variant="h6" sx={{color: 'text.secondary'}}>
                                        {currencyFormatter.format(productDetail?.price, {locale: 'GH'})}
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} md="auto">
                                    <Stack spacing={2} direction="row" alignItems="center">
                                        <Rating
                                            readOnly={true}
                                            precision={0.1}
                                            value={productDetail?.rating}
                                        />
                                        <Typography sx={{color: 'text.primary'}} variant="body2">
                                            ({productDetail?.reviewsCount})
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" sx={{color: 'text.secondary'}}>
                                {productDetail?.shortDescription}
                            </Typography>
                            <Box>
                                <Typography variant="body1" sx={{color: 'text.primary'}}>
                                    Sizes
                                </Typography>
                            </Box>
                            <Box>
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <ButtonGroup>
                                        <Button size="large" color="secondary">-</Button>
                                        <Button size="large" color="secondary">-</Button>
                                        <Button size="large" color="secondary">+</Button>
                                    </ButtonGroup>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default ProductDetailPage;
