import {Box, Card, CardContent, CircularProgress, Container, Grid, Stack, TextField, Typography} from "@mui/material";
import Layout from "../../components/layout/layout";
import Info from "../../components/shared/info";
import {CalendarToday, Call, Facebook, Instagram, LocationCity, LocationOn, Mail, Schedule} from "@mui/icons-material";
import {blue, pink, red, yellow} from "@mui/material/colors";
import contactLogo from "./../../assets/images/contact.png";
import profileLogo from "./../../assets/images/profile.jpg";
import {useState} from "react";
import {useSelector} from "react-redux";
import {selectContact} from "../../redux/contact/contact-reducer";
import {LoadingButton} from "@mui/lab";

const ContactPage = () => {

    const [contact, setContact] = useState({});
    const [error, setError] = useState({});
    const {name, email, subject, message} = contact;
    const {contactLoading} = useSelector(selectContact);

    const handleChange = event => {
        setContact({...contact, [event.target.name]: event.target.value});
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (!name) {
            setError({...error, 'name': 'Field required'});
            return;
        } else {
            setError({...error, 'name': null});
        }

        console.log(contact)
    }

    return (
        <Layout>
            <Box sx={{backgroundColor: 'background.default', pb: 8, pt: 16}}>
                <Box>
                    <Container>
                        <Box>
                            <Typography
                                sx={{
                                    mb: 2,
                                    color: 'secondary.main',
                                    fontWeight: 700
                                }} variant="h5">
                                Contact
                            </Typography>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12} md={3}>
                                    <Info
                                        elevation={0}
                                        variant="elevation"
                                        title="Email"
                                        value="info@smashsneakers.com"
                                        color="red"
                                        icon={<Mail sx={{color: red[600]}}/>}
                                        link="mailto:info@smashsneakers.com"
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Info
                                        elevation={0}
                                        variant="elevation"
                                        title="Phone"
                                        value="+233 54 0048 319"
                                        color="yellow"
                                        icon={<Call sx={{color: yellow[800]}}/>}
                                        link="tel:+233540048319"
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Info
                                        elevation={0}
                                        variant="elevation"
                                        title="Instagram"
                                        value="smashsneakers"
                                        color="pink"
                                        icon={<Instagram sx={{color: pink[600]}}/>}
                                        link="https://instagram.com/smashsneakers"
                                    />
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Info
                                        elevation={0}
                                        variant="elevation"
                                        title="Facebook"
                                        value="Smash Sneakers"
                                        color="blue"
                                        icon={<Facebook sx={{color: blue[600]}}/>}
                                        link="https://facebook.com/smash.sneakers"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Box>

            <Box sx={{backgroundColor: 'background.paper', pb: 8, pt: 12}}>
                <Container>
                    <Typography
                        sx={{fontWeight: 700, mb: 3}}
                        variant="h5">
                        Get In Touch
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                        We are always happy to hear from you. shoot us a message and we'll be happy
                        to
                        answer your questions.
                    </Typography>
                </Container>
                <Container>
                    <Grid container={true} spacing={5}>
                        <Grid item={true} xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Stack mb={4} direction="column" spacing={2}>
                                        <TextField
                                            fullWidth={true}
                                            name="name"
                                            placeholder="Enter your full name"
                                            label="Name"
                                            variant="outlined"
                                            margin="normal"
                                            value={name}
                                            onChange={handleChange}
                                            required={true}
                                            size="medium"
                                            color="secondary"
                                            error={Boolean(error.name)}
                                            helperText={error.name}
                                            type="text"
                                        />
                                        <TextField
                                            fullWidth={true}
                                            name="email"
                                            placeholder="Enter email"
                                            label="Email"
                                            variant="outlined"
                                            margin="normal"
                                            value={email}
                                            onChange={handleChange}
                                            required={true}
                                            size="medium"
                                            color="secondary"
                                            error={Boolean(error.email)}
                                            helperText={error.email}
                                            type="email"
                                        />

                                        <TextField
                                            fullWidth={true}
                                            name="subject"
                                            placeholder="Enter subject"
                                            label="Subject"
                                            variant="outlined"
                                            margin="normal"
                                            value={subject}
                                            onChange={handleChange}
                                            required={true}
                                            size="medium"
                                            color="secondary"
                                            error={Boolean(error.subject)}
                                            helperText={error.subject}
                                            type="text"
                                        />

                                        <TextField
                                            fullWidth={true}
                                            name="message"
                                            placeholder="Enter message"
                                            label="Message"
                                            variant="outlined"
                                            margin="normal"
                                            value={message}
                                            onChange={handleChange}
                                            required={true}
                                            size="large"
                                            color="secondary"
                                            error={Boolean(error.message)}
                                            helperText={error.message}
                                            type="text"
                                            multiline={true}
                                            minRows={6}
                                        />
                                    </Stack>

                                    <Grid container={true}>
                                        <Grid item={true} xs={12} md="auto">
                                            <LoadingButton
                                                fullWidth={true}
                                                sx={{my: 1.5}}
                                                color="secondary"
                                                loadingPosition="start"
                                                onClick={handleSubmit}
                                                variant="contained"
                                                indicatorPosition
                                                loading={contactLoading}
                                                loadingIndicator={<CircularProgress size={20} color="secondary"/>}>
                                                Send Message
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <img
                                alt="Contact Us"
                                src={contactLogo}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center'
                                }}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', pb: 8, py: 12}}>
                <Container>
                    <Typography
                        align="center"
                        sx={{
                            mb: 4,
                            color: 'secondary.main',
                            fontWeight: 700
                        }} variant="h5">
                        Visit our Shops
                    </Typography>
                </Container>
                <Container>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12} md={6} spacing={2}>
                            <img
                                alt="Contact Us"
                                src={profileLogo}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center'
                                }}/>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Stack mb={2} direction="column" spacing={2}>
                                <Info
                                    icon={<LocationCity sx={{color: red[600]}}/>}
                                    title="Location"
                                    value="Magic Trim, East Legon"
                                />

                                <Info
                                    title="Landmark"
                                    value="Magic Trim, East Legon"
                                    icon={<LocationOn sx={{color: red[600]}}/>}
                                />
                            </Stack>
                            <Stack direction="column" spacing={2}>
                                <Info
                                    icon={<Schedule sx={{color: red[600]}}/>}
                                    title="Working Hours"
                                    value="8:30 AM - 5:30 PM"
                                />

                                <Info
                                    icon={<CalendarToday sx={{color: red[600]}}/>}
                                    title="Working Days"
                                    value="Monday - Friday"
                                />

                                <Info
                                    icon={<Schedule sx={{color: red[600]}}/>}
                                    title="Saturday"
                                    value="8:30 AM - 5:30 PM"
                                />
                                <Info
                                    icon={<Schedule sx={{color: red[600]}}/>}
                                    title="Sunday"
                                    value="Closed"
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', pb: 8, pt: 12}}>

                <Container>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12} md={6}>
                            <Stack mb={2} direction="column" spacing={2}>
                                <Info
                                    icon={<LocationCity sx={{color: red[600]}}/>}
                                    title="Location"
                                    value="Magic Trim, East Legon"
                                />

                                <Info
                                    title="Landmark"
                                    value="Magic Trim, East Legon"
                                    icon={<LocationOn sx={{color: red[600]}}/>}
                                />
                            </Stack>
                            <Stack direction="column" spacing={2}>
                                <Info
                                    icon={<Schedule sx={{color: red[600]}}/>}
                                    title="Working Hours"
                                    value="8:30 AM - 5:30 PM"
                                />

                                <Info
                                    icon={<CalendarToday sx={{color: red[600]}}/>}
                                    title="Working Days"
                                    value="Monday - Friday"
                                />

                                <Info
                                    icon={<Schedule sx={{color: red[600]}}/>}
                                    title="Saturday"
                                    value="8:30 AM - 5:30 PM"
                                />
                                <Info
                                    icon={<Schedule sx={{color: red[600]}}/>}
                                    title="Sunday"
                                    value="Closed"
                                />
                            </Stack>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <img
                                alt="Contact Us"
                                src={profileLogo}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center'
                                }}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Layout>
    )
}

export default ContactPage;
