import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Layout from "../../components/layout/layout";
import aboutImage from "./../../assets/images/profile.jpg";
import {Link} from "react-router-dom";
import Choice from "../../components/shared/choice";
import {useSelector} from "react-redux";
import {selectChoice} from "../../redux/choices/choice-reducer";
import Testimonial from "../../components/shared/testimonial";
import {selectTestimonial} from "../../redux/testimonials/testimonial-reducer";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {selectContact} from "../../redux/contact/contact-reducer";
import FAQ from "../../components/shared/faq";
import {selectFAQ} from "../../redux/faqs/faq-reducer";

const AboutPage = () => {

    const {choices} = useSelector(selectChoice);
    const {testimonials} = useSelector(selectTestimonial);

    const [contact, setContact] = useState({});
    const [error, setError] = useState({});
    const {name, email, subject, message} = contact;
    const {contactLoading} = useSelector(selectContact);
    const {faqs} = useSelector(selectFAQ);

    const handleChange = event => {
        setContact({...contact, [event.target.name]: event.target.value});
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (!name) {
            setError({...error, 'name': 'Field required'});
            return;
        } else {
            setError({...error, 'name': null});
        }

        console.log(contact)
    }

    return (
        <Layout>
            <Box sx={{py: 8}}>
                <Box sx={{py: 8, backgroundColor: 'background.default'}}>
                    <Container>
                        <Grid container={true} spacing={5} alignItems="center">
                            <Grid item={true} xs={12} md={6}>
                                <img style={{maxWidth: '100%'}} src={aboutImage} alt="About us"/>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <Typography
                                    mb={3}
                                    variant="h5"
                                    sx={{color: 'secondary.main', fontWeight: 700}}>
                                    Who are we?
                                </Typography>
                                <Typography mb={3} variant="body1" sx={{color: 'text.primary'}}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                    molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
                                    numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
                                    optio, eaque rerum!
                                </Typography>
                                <Link to="/contact" style={{textDecoration: 'none'}}>
                                    <Button
                                        fullWidth={true}
                                        sx={{py: 1.5}}
                                        color="secondary"
                                        loadingPosition="start"
                                        variant="contained">
                                        Contact Us
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box sx={{py: 8, backgroundColor: 'background.paper'}}>
                    <Container sx={{py: 8,}}>
                        <Typography
                            variant="h4"
                            mb={2}
                            sx={{
                                color: 'text.primary',
                                textTransform: 'uppercase',
                                textAlign: 'center'
                            }}>
                            Why Choose Us
                        </Typography>
                    </Container>
                    <Container>
                        <Grid container={true} spacing={2}>
                            {choices && choices.map((choice, index) => {
                                return (
                                    <Grid key={index} xs={12} md={4} lg={3} item={true}>
                                        <Choice elevation={1} variant="elevation" choice={choice}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </Box>

                <Box sx={{py: 8, backgroundColor: 'background.default'}}>
                    <Container sx={{py: 8,}}>
                        <Typography
                            variant="h4"
                            mb={2}
                            sx={{
                                color: 'text.primary',
                                textTransform: 'uppercase',
                                textAlign: 'center'
                            }}>
                            What our clients say
                        </Typography>
                    </Container>
                    <Container>
                        <Grid container={true} spacing={2}>
                            {testimonials && testimonials.map((testimonial, index) => {
                                return (
                                    <Grid key={index} xs={12} md={4} lg={3} item={true}>
                                        <Testimonial elevation={1} variant="elevation" testimonial={testimonial}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </Box>

                <Box sx={{py: 8, backgroundColor: 'background.paper'}}>
                    <Container>
                        <Grid container={true} spacing={5}>
                            <Grid item={true} xs={12} md={6}>
                                <Typography
                                    mb={3}
                                    variant="h5"
                                    sx={{color: 'secondary.main', fontWeight: 700}}>
                                    FAQ
                                </Typography>
                                <Stack divider={<Divider variant="fullWidth" light={true}/>} spacing={2}>
                                    {faqs && faqs.map((faq, index) => {
                                        return (
                                            <Box key={index}>
                                                <FAQ elevation={0} variant='container' faq={faq}/>
                                            </Box>
                                        )
                                    })}
                                </Stack>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            sx={{fontWeight: 700, mb: 3, color: 'secondary.main'}}
                                            variant="h5">
                                            Ask a Question
                                        </Typography>
                                        <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                                            Can't find the answers you're looking for? Shoot us a message!
                                        </Typography>

                                        <Stack mb={4} direction="column" spacing={2}>
                                            <TextField
                                                fullWidth={true}
                                                name="name"
                                                placeholder="Enter your full name"
                                                label="Name"
                                                variant="outlined"
                                                margin="normal"
                                                value={name}
                                                onChange={handleChange}
                                                required={true}
                                                size="medium"
                                                color="secondary"
                                                error={Boolean(error.name)}
                                                helperText={error.name}
                                                type="text"
                                            />
                                            <TextField
                                                fullWidth={true}
                                                name="email"
                                                placeholder="Enter email"
                                                label="Email"
                                                variant="outlined"
                                                margin="normal"
                                                value={email}
                                                onChange={handleChange}
                                                required={true}
                                                size="medium"
                                                color="secondary"
                                                error={Boolean(error.email)}
                                                helperText={error.email}
                                                type="email"
                                            />

                                            <TextField
                                                fullWidth={true}
                                                name="subject"
                                                placeholder="Enter subject"
                                                label="Subject"
                                                variant="outlined"
                                                margin="normal"
                                                value={subject}
                                                onChange={handleChange}
                                                required={true}
                                                size="medium"
                                                color="secondary"
                                                error={Boolean(error.subject)}
                                                helperText={error.subject}
                                                type="text"
                                            />

                                            <TextField
                                                fullWidth={true}
                                                name="message"
                                                placeholder="Enter message"
                                                label="Message"
                                                variant="outlined"
                                                margin="normal"
                                                value={message}
                                                onChange={handleChange}
                                                required={true}
                                                size="large"
                                                color="secondary"
                                                error={Boolean(error.message)}
                                                helperText={error.message}
                                                type="text"
                                                multiline={true}
                                                minRows={6}
                                            />
                                        </Stack>

                                        <Grid container={true}>
                                            <Grid item={true} xs={12} md="auto">
                                                <LoadingButton
                                                    fullWidth={true}
                                                    sx={{py: 1.5}}
                                                    color="secondary"
                                                    loadingPosition="start"
                                                    onClick={handleSubmit}
                                                    variant="contained"
                                                    indicatorPosition
                                                    loading={contactLoading}
                                                    loadingIndicator={<CircularProgress size={20} color="secondary"/>}>
                                                    Send Message
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Layout>
    )
}

export default AboutPage;
