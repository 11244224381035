import {combineReducers} from "redux";
import uiReducer from "./ui/ui-reducer";
import testimonialReducer from "./testimonials/testimonial-reducer";
import productReducer from "./products/product-reducer";
import orderReducer from "./orders/order-reducer";
import faqReducer from "./faqs/faq-reducer";
import authReducer from "./auth/auth-reducer";
import brandReducer from "./brands/brand-reducer";
import choiceReducer from "./choices/choice-reducer";
import contactReducer from "./contact/contact-reducer";
import galleryReducer from "./gallery/gallery-reducer";

const rootReducer = combineReducers({
    ui: uiReducer,
    testimonial: testimonialReducer,
    product: productReducer,
    order: orderReducer,
    faq: faqReducer,
    auth: authReducer,
    brand: brandReducer,
    choice: choiceReducer,
    contact: contactReducer,
    galleryItem: galleryReducer
});

export default rootReducer;
