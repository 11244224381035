import {Avatar, Card, CardContent, Rating, Stack, Typography} from "@mui/material";
import {UTILS} from "../../utils/utils";

const Testimonial = ({testimonial, elevation}) => {
    return (
        <Card
            variant="outlined"
            elevation={elevation}
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'default.paper'
            }}>
            <CardContent sx={{flexGrow: 1}}>
                <Stack spacing={2} direction="column">
                    <Stack direction="row" justifyContent="center">
                        {
                            testimonial.user.image ?
                                <Avatar src={testimonial.user.image}/> :
                                <Avatar>{UTILS.getInitials(testimonial.user.name)}</Avatar>
                        }
                    </Stack>
                    <Stack direction="row" justifyContent="center">
                        <Rating
                            size="large"
                            value={testimonial.rating}
                            readOnly={true}
                        />
                    </Stack>
                    <Typography
                        align="center"
                        variant="h6"
                        sx={{color: 'text.primary'}}>
                        {testimonial.user.name}
                    </Typography>
                    <Typography
                        align="center"
                        variant="body2"
                        sx={{color: 'text.secondary'}}>
                        {testimonial.text}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Testimonial;
