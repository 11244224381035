import productImage from './../../assets/images/banner.jpg';
import shoe1 from './../../assets/images/shoe1.jpg';
import shoe2 from './../../assets/images/shoe2.jpg';
import shoe3 from './../../assets/images/shoe3.jpg';
import image from "../../assets/images/profile.jpg";

//pexels.com

export const products = [
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        reviewsCount: 15,
        _id: 1,
        detailedDescription: '',
        shortDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laboru numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentiu optio, eaque rerum! Provident similique accusantium nemo autem',
        gender: '',
        colors: ['white', 'red', 'blue'],
        reviews: [
            {
                user: {
                    name: 'Stanley Hayford',
                },
                text: 'Quality american brands at affordable prices. Beautiful stuff',
                rating: 5,
                updatedAt: new Date(2020, 9, 24)
            },
            {
                user: {
                    name: 'Usermaatre Setepenre',
                    image,
                },
                text: "You don't want to miss out on these cool footwear. Pamper your feet!",
                rating: 4.5,
                updatedAt: new Date(2020, 8, 24)
            },
            {
                user: {
                    name: 'Zeus',
                    image,
                },
                text: "Breathless. I found my favorite shop. Impressive",
                rating: 5,
                updatedAt: new Date(2020, 8, 25)
            },
            {
                user: {
                    name: 'Stanley Hayford',
                },
                text: 'Quality american brands at affordable prices. Beautiful stuff',
                rating: 5,
                updatedAt: new Date(2020, 9, 24)
            },
        ],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        },
        gallery: [productImage, productImage, productImage, productImage, productImage],
        sizes: ['S', 'M', 'L', 'XL'],
        tags: ['Nike', 'Air Max', ''],
        reviewSummary: {
            5: 4,
            4: 4,
            3: 1,
            2: 1,
            1: 0,
            average: 4.1,
            count: 23
        }
    },
    {
        image: shoe1,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: shoe2,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: shoe3,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Yeezy',
        brand: 'Adidas',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    }
]

export const bestSellers = [
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    }
]

export const featuredProducts = [
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    }
]

export const onSaleProducts = [
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    }
]

export const newArrivals = [
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    },
    {
        image: productImage,
        name: 'Air Max',
        brand: 'Nike',
        price: 500,
        rating: 5,
        _id: 1,
        detailedDescription: '',
        gender: '',
        color: '',
        reviews: [],
        status: '',
        category: '',
        onSale: {
            price: 45,
            status: true,
        },
        bestSeller: {
            status: true
        },
        newArrival: {
            status: false
        },
        isFeatured: {
            status: true
        }
    }
]
