import {AUTH_ACTION_TYPES} from "./auth-action-types";

const INITIAL_STATE = {
    authData: {
        firstName: 'Stanley',
        lastName: 'Hayford',
        gender: 'Male',
        username: 'sahayford',
        email: 'hayfordstanley@gmail.com',
        phoneNumber: "+233270048319",
        emergencyPhoneNumber: "+233555180048",
        dateOfBirth: new Date(1993, 7, 29),
        status: 'Active',
        address: {
            country: "Ghana",
            city: "Accra",
            region: "Greater Accra",
            addressLine1: "Atakorah Estates 2",
            addressLine2: "Ashomang Estate"
        }
    },
    authLoading: false,
    authError: null,
    token: "1234567890",
    authMessage: null
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_ACTION_TYPES.SIGN_IN_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true
            }

        case AUTH_ACTION_TYPES.SIGN_IN_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                token: action.payload.token,
                authData: action.payload.data
            }

        case AUTH_ACTION_TYPES.SIGN_IN_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
                authData: null
            }


        case AUTH_ACTION_TYPES.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true
            }

        case AUTH_ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                token: action.payload.token,
                authData: action.payload.data
            }

        case AUTH_ACTION_TYPES.FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
                authData: null
            }


        case AUTH_ACTION_TYPES.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true
            }

        case AUTH_ACTION_TYPES.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                token: action.payload.token,
                authData: action.payload.data
            }

        case AUTH_ACTION_TYPES.RESET_PASSWORD_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
                authData: null
            }


        case AUTH_ACTION_TYPES.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true,
                authMessage: null
            }

        case AUTH_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                authMessage: action.payload
            }

        case AUTH_ACTION_TYPES.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
                authData: null,
                authMessage: null
            }


        case AUTH_ACTION_TYPES.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true
            }

        case AUTH_ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                authData: action.payload.data
            }

        case AUTH_ACTION_TYPES.UPDATE_PROFILE_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
            }


        case AUTH_ACTION_TYPES.LOGOUT_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true
            }

        case AUTH_ACTION_TYPES.LOGOUT_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                token: action.payload.token,
                authData: action.payload.data
            }

        case AUTH_ACTION_TYPES.LOGOUT_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
                authData: null
            }


        case AUTH_ACTION_TYPES.GET_PROFILE_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true
            }

        case AUTH_ACTION_TYPES.GET_PROFILE_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                token: action.payload.token,
                authData: action.payload.data,
                bankAccount: action.payload.bankAccount
            }

        case AUTH_ACTION_TYPES.GET_PROFILE_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
                authData: null
            }


        case AUTH_ACTION_TYPES.VERIFY_ACCOUNT_REQUEST:
            return {
                ...state,
                authError: null,
                authLoading: true
            }

        case AUTH_ACTION_TYPES.VERIFY_ACCOUNT_SUCCESS:
            return {
                ...state,
                authError: null,
                authLoading: false,
                token: action.payload.token,
                authData: action.payload.data
            }

        case AUTH_ACTION_TYPES.VERIFY_ACCOUNT_FAIL:
            return {
                ...state,
                authError: action.payload,
                authLoading: false,
                authData: null
            }

        case AUTH_ACTION_TYPES.PREVIOUS_PAGE:
            return {
                ...state,
                page: state.page - 1
            }

        case AUTH_ACTION_TYPES.NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1
            }
        case AUTH_ACTION_TYPES.GO_TO_PAGE:
            return {
                ...state,
                page: action.payload
            }

        case AUTH_ACTION_TYPES.SAVE_PERSONAL_INFORMATION:
            return {
                ...state,
                personalInfo: action.payload
            }

        case AUTH_ACTION_TYPES.SAVE_BANK_ACCOUNT_INFORMATION:
            return {
                ...state,
                bankAccountInfo: action.payload
            }
        case AUTH_ACTION_TYPES.SAVE_PAYMENT:
            return {
                ...state,
                paymentInfo: action.payload
            }

        case AUTH_ACTION_TYPES.SAVE_ACCOUNT_INFORMATION:
            return {
                ...state,
                accountInfo: action.payload
            }
        default:
            return state;
    }
}

export const selectAuth = state => state.auth

export default authReducer;
