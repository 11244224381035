import {Box, Button, Container, Grid, Typography} from "@mui/material";
import Layout from "../../components/layout/layout";
import {useSelector} from "react-redux";
import {selectBrand} from "../../redux/brands/brand-reducer";
import Brand from "../../components/shared/brand";
import {selectProduct} from "../../redux/products/product-reducer";
import Product from "../../components/shared/product";
import {selectTestimonial} from "../../redux/testimonials/testimonial-reducer";
import Testimonial from "../../components/shared/testimonial";
import {selectChoice} from "../../redux/choices/choice-reducer";
import Choice from "../../components/shared/choice";
import {Link} from "react-router-dom";

const HomePage = () => {

    const {brands} = useSelector(selectBrand);
    const {bestSellers, featuredProducts, newArrivals} = useSelector(selectProduct);
    const {testimonials} = useSelector(selectTestimonial);
    const {choices} = useSelector(selectChoice);

    return (
        <Layout>
            <Box sx={{mt: {xs: 6, md: 8}, backgroundColor: 'background.default'}}>
                <Box sx={{position: 'relative', height: {xs: '100vh', md: '100vh', lg: '92vh'}}}>
                    <Box sx={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                        {/*<img*/}
                        {/*    src={banner}*/}
                        {/*    alt="Banner"*/}
                        {/*    style={{*/}
                        {/*        height: '100%',*/}
                        {/*        width: '100%',*/}
                        {/*        objectPosition: 'center',*/}
                        {/*        objectFit: 'cover'*/}
                        {/*    }}/>*/}
                    </Box>
                    <Container
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        <Grid container={true} sx={{}}>
                            <Grid xs={12} md={6}>

                                <Typography mb={3} sx={{color: 'text.primary'}} variant="h3">
                                    Smash Sneakers
                                </Typography>
                                <Typography mb={3} sx={{color: 'text.primary'}} variant="h6">
                                    A city of authentic sneakers with affordable prices
                                </Typography>
                                <Grid container={true} spacing={2} alignItems="center">
                                    <Grid item={true} xs={12} md="auto">
                                        <Link to="/products" style={{textDecoration: 'none'}}>
                                            <Button
                                                fullWidth={true}
                                                color="secondary"
                                                size="large"
                                                variant="contained"
                                                disableElevation={true}>
                                                Shop Now
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid item={true} xs={12} md="auto">
                                        <Link to="/contact" style={{textDecoration: 'none'}}>
                                            <Button
                                                fullWidth={true}
                                                color="secondary"
                                                size="large"
                                                variant="outlined"
                                                disableElevation={true}>
                                                Contact Us
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.paper', py: 5}}>
                <Container>
                    <Typography
                        mb={4}
                        variant="h5"
                        sx={{
                            color: 'text.primary',
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }}>
                        Our top brands
                    </Typography>
                </Container>
                <Box>
                    <Grid container={true} spacing={0}>
                        {brands && brands.map(brand => {
                            return (
                                <Grid key={brand.name} item={true} xs={12} md={4} lg={2}>
                                    <Brand brand={brand}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
            <Box sx={{pb: 8, backgroundColor: 'background.default'}}>
                <Container sx={{py: 8,}}>
                    <Typography
                        variant="h5"
                        mb={2}
                        sx={{
                            color: 'text.primary',
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }}>
                        Featured Sneakers
                    </Typography>
                </Container>
                <Container>
                    <Grid container={true} spacing={2}>
                        {featuredProducts && featuredProducts.map(featuredProduct => {
                            return (
                                <Grid key={featuredProduct._id} xs={12} md={4} lg={3} item={true}>
                                    <Product elevation={0} variant='container' product={featuredProduct}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{pb: 8, backgroundColor: 'background.paper'}}>
                <Container sx={{py: 8,}}>
                    <Typography
                        variant="h5"
                        mb={2}
                        sx={{
                            color: 'text.primary',
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }}>
                        Best Sellers
                    </Typography>
                </Container>
                <Container>
                    <Grid container={true} spacing={2}>
                        {bestSellers && bestSellers.map(bestSeller => {
                            return (
                                <Grid key={bestSeller._id} xs={12} md={4} lg={3} item={true}>
                                    <Product elevation={1} variant="outlined" product={bestSeller}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{pb: 8, backgroundColor: 'background.default'}}>
                <Container sx={{py: 8,}}>
                    <Typography
                        variant="h5"
                        mb={2}
                        sx={{
                            color: 'text.primary',
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }}>
                        New Arrivals
                    </Typography>
                </Container>
                <Container>
                    <Grid container={true} spacing={2}>
                        {newArrivals && newArrivals.map(newArrival => {
                            return (
                                <Grid key={newArrival._id} xs={12} md={4} lg={3} item={true}>
                                    <Product elevation={0} variant="outline" product={newArrival}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{pb: 8, backgroundColor: 'background.paper'}}>
                <Container sx={{py: 8,}}>
                    <Typography
                        variant="h5"
                        mb={2}
                        sx={{
                            color: 'text.primary',
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }}>
                        What our clients say
                    </Typography>
                </Container>
                <Container>
                    <Grid container={true} spacing={2}>
                        {testimonials && testimonials.map((testimonial, index) => {
                            return (
                                <Grid key={index} xs={12} md={4} lg={3} item={true}>
                                    <Testimonial elevation={1} variant="outlined" testimonial={testimonial}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{pb: 8, backgroundColor: 'background.default'}}>
                <Container sx={{py: 8,}}>
                    <Typography
                        variant="h5"
                        mb={2}
                        sx={{
                            color: 'text.primary',
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }}>
                        Why Choose Us
                    </Typography>
                </Container>
                <Container>
                    <Grid container={true} spacing={2}>
                        {choices && choices.map((choice, index) => {
                            return (
                                <Grid key={index} xs={12} md={4} lg={3} item={true}>
                                    <Choice elevation={1} variant="elevation" choice={choice}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
        </Layout>
    )
}

export default HomePage;
