import {applyMiddleware, createStore} from "redux";
import rootReducer from "./root-reducer";
import thunk from "redux-thunk";
import {SMASH_SNEAKERS_CONSTANTS} from "../constants/constants";

const token = localStorage.getItem(SMASH_SNEAKERS_CONSTANTS.SMASH_SNEAKERS_AUTH_TOKEN_KEY) ?
    localStorage.getItem(SMASH_SNEAKERS_CONSTANTS.SMASH_SNEAKERS_AUTH_TOKEN_KEY) : null;

const authData = localStorage.getItem(SMASH_SNEAKERS_CONSTANTS.SMASH_SNEAKERS_AUTH_DATA_KEY) ?
    JSON.parse(localStorage.getItem(SMASH_SNEAKERS_CONSTANTS.SMASH_SNEAKERS_AUTH_DATA_KEY)) : null;

const themeVariant = localStorage.getItem(SMASH_SNEAKERS_CONSTANTS.SMASH_SNEAKERS_THEME_VARIANT_KEY) ?
    localStorage.getItem(SMASH_SNEAKERS_CONSTANTS.SMASH_SNEAKERS_THEME_VARIANT_KEY) : null;

const INITIAL_STATE = {
    auth: {
        token: "1234567890",
        authData: {
            firstName: 'Stanley',
            lastName: 'Hayford',
            gender: 'Male',
            username: 'sahayford',
            email: 'hayfordstanley@gmail.com',
            phoneNumber: "+233270048319",
            emergencyPhoneNumber: "+233555180048",
            dateOfBirth: new Date(1993, 7, 29),
            status: 'Active',
            address: {
                country: "Ghana",
                city: "Accra",
                region: "Greater Accra",
                addressLine1: "Atakorah Estates 2",
                addressLine2: "Ashomang Estate"
            },
        }
    },
    ui: {
        themeVariant,
        drawerOpen: false
    }
};

const store = createStore(rootReducer, INITIAL_STATE, applyMiddleware(thunk));
export default store;
