import {Avatar, Card, CardContent, Link, Stack, Typography} from "@mui/material";

const Info = ({title, value, icon, link, elevation}) => {
    return (
        <Link href={link} style={{textDecoration: 'none'}}>
            <Card sx={{backgroundColor: 'default.paper'}} elevation={elevation} variant="outlined">
                <CardContent>
                    <Stack alignItems="center" direction="row" spacing={2}>
                        <Avatar sx={{backgroundColor: 'light.secondary'}}>
                            {icon}
                        </Avatar>
                        <Stack direction="column">
                            <Typography sx={{color: 'text.secondary'}} variant="body2">{title}</Typography>
                            <Typography sx={{color: 'text.primary'}} variant="body2">{value}</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Link>
    )
}

export default Info;
