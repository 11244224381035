import {Box, Card, CardContent, CardMedia, Rating, Stack, Typography} from "@mui/material";
import {AddShoppingCartOutlined, FavoriteBorder} from "@mui/icons-material";
import {Link} from "react-router-dom";

const Product = ({product,  elevation}) => {
    return (
        <Card sx={{backgroundColor: 'default.paper'}} variant="outlined" elevation={elevation}>
            <CardContent>
                <CardMedia
                    component="img"
                    src={product.image}
                    sx={{
                        height: 200,
                        objectFit: 'cover',
                        objectPosition: 'top',
                        borderRadius: 1,
                        mb: 2
                    }}
                />
                <Stack spacing={2} direction="column">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" sx={{color: 'text.primary'}}>
                            {product.name}
                        </Typography>
                        <Link to={`/products/${product._id}`} style={{textDecoration: 'none'}}>
                            <Box
                                sx={{
                                    backgroundColor: 'light.secondary',
                                    borderRadius: 1,
                                    padding: 0.5,
                                    cursor: 'pointer'
                                }}>
                                <Typography
                                    sx={{color: 'secondary.main'}}
                                    variant="body2">
                                    Detail
                                </Typography>
                            </Box>

                        </Link>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box
                            sx={{
                                borderColor: 'light.secondary',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderRadius: 1,
                                padding: 1
                            }}>
                            <Typography
                                sx={{color: 'secondary.main'}}
                                variant="body2">
                                {product.brand}
                            </Typography>
                        </Box>
                        <Box>
                            <Rating
                                size="small"
                                value={product.rating}
                                readOnly={true}
                                color="secondary"
                                precision={0.1}
                            />
                        </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography sx={{color: 'text.secondary'}} variant="body2">${product.price}</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <FavoriteBorder
                                sx={{
                                    backgroundColor: 'light.secondary',
                                    borderRadius: 0.5,
                                    padding: 0.5,
                                    color: 'secondary.main'
                                }}
                                fontSize="small"/>
                            <AddShoppingCartOutlined
                                sx={{
                                    backgroundColor: 'light.secondary',
                                    borderRadius: 0.5,
                                    padding: 0.5,
                                    color: 'secondary.main'
                                }}
                                fontSize="small"/>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Product;
