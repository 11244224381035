import {TESTIMONIAL_ACTION_TYPES} from "./testimonial-action-types";
import {testimonials} from "./data";

const INITIAL_STATE = {
    testimonials: [...testimonials],
    testimonialLoading: false,
    testimonialError: null,
    testimonialMessage: null,
    totalTestimonials: 0
};


const testimonialReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TESTIMONIAL_ACTION_TYPES.GET_TESTIMONIALS_REQUEST:
            return {
            ...state,
                testimonialError: null,
                testimonialMessage: null,
                testimonialLoading: true
            }

        case TESTIMONIAL_ACTION_TYPES.GET_TESTIMONIALS_SUCCESS:
            return {
                ...state,
                testimonialError: null,
                testimonialMessage: null,
                testimonialLoading: true,
                testimonials: action.payload.data,
                totalTestimonials: action.payload.count
            }

        case TESTIMONIAL_ACTION_TYPES.GET_TESTIMONIALS_FAIL:
            return {
                ...state,
                testimonialError: null,
                testimonialMessage: null,
                testimonialLoading: true
            }
        default:
            return state;
    }
}

export const selectTestimonial = state => state.testimonial;

export default testimonialReducer;
