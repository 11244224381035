import Layout from "../../components/layout/layout";
import {
    Alert,
    AlertTitle,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    Grid,
    LinearProgress,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {useDispatch, useSelector} from "react-redux";
import validator from "validator";
import {useNavigate} from "react-router";
import {selectAuth} from "../../redux/auth/auth-reducer";
import {AUTH_ACTION_CREATORS} from "../../redux/auth/auth-action-creators";

const SettingsPage = () => {

    const {authLoading, token, authData, authError} = useSelector(selectAuth);

    useEffect(() => {
        if (authData) setUser(authData);
    }, [authData]);


    const [user, setUser] = useState({});
    const [error, setError] = useState({});
    const {
        firstName, lastName, email, username, phoneNumber, emergencyPhoneNumber, address
    } = user;

    const {country, region, city, addressLine1, addressLine2} = address;

    const handleUserChange = event => {
        setUser({...user, [event.target.name]: event.target.value});
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        if (!firstName) {
            setError({error, firstName: 'Field required'});
            return;
        } else {
            setError({error, firstName: null});
        }

        if (!lastName) {
            setError({error, lastName: 'Field required'});
            return;
        } else {
            setError({error, lastName: null});
        }

        if (!email) {
            setError({error, email: 'Field required'});
            return;
        } else {
            setError({error, email: null});
        }
        if (!validator.isEmail(email)) {
            setError({error, email: 'Invalid email'});
            return;
        } else {
            setError({error, email: null});
        }

        if (!phoneNumber) {
            setError({error, phoneNumber: 'Field required'});
            return;
        } else {
            setError({error, phoneNumber: null});
        }

        if (!validator.isMobilePhone(phoneNumber)) {
            setError({error, phoneNumber: 'Invalid phone'});
            return;
        } else {
            setError({error, phoneNumber: null});
        }


        if (!emergencyPhoneNumber) {
            setError({error, emergencyPhoneNumber: 'Field required'});
            return;
        } else {
            setError({error, emergencyPhoneNumber: null});
        }
        if (!validator.isMobilePhone(emergencyPhoneNumber)) {
            setError({error, emergencyPhoneNumber: 'Invalid phone'});
            return;
        } else {
            setError({error, emergencyPhoneNumber: null});
        }
        dispatch(AUTH_ACTION_CREATORS.updateProfile(
            {firstName, lastName, email, username, phoneNumber, emergencyPhoneNumber},
            token, navigate));
    }


    return (
        <Layout>
            {authLoading && <LinearProgress variant="query" color="secondary"/>}
            <Container sx={{py: 8}}>
                <Grid
                    sx={{my: 4}}
                    container={true}
                    justifyContent="space-between"
                    spacing={2}>
                    <Grid item={true} xs={12} md="auto">
                        <Typography variant="h4">
                            Update Profile
                        </Typography>
                    </Grid>

                    <Grid item={true} xs={12} md="auto">
                        <LoadingButton
                            loading={authLoading}
                            loadingIndicator={<CircularProgress size="small" color="secondary"/>}
                            onClick={handleSubmit}
                            size="large"
                            color="secondary"
                            fullWidth={true}
                            disableElevation={true}
                            variant="outlined">
                            Update Profile
                        </LoadingButton>
                    </Grid>
                </Grid>

                <Divider light={true} variant="fullWidth" sx={{my: 4}}/>

                <Grid
                    spacing={2}
                    container={true}
                    justifyContent="space-between">
                    <Grid item={true} xs={12} md={6}>
                        <Card elevation={0}>
                            {authLoading && <LinearProgress variant="query" color="primary"/>}
                            <CardContent>
                                {
                                    authError && (
                                        <Alert sx={{mb: 2}} severity="error">
                                            <AlertTitle>{authError}</AlertTitle>
                                        </Alert>
                                    )
                                }
                                <Stack spacing={2} direction="column">
                                    <Typography variant="h6">
                                        Personal Information
                                    </Typography>
                                    <TextField
                                        label="First Name"
                                        fullWidth={true}
                                        name="firstName"
                                        required={true}
                                        variant="outlined"
                                        value={authData && firstName}
                                        error={Boolean(error.firstName)}
                                        helperText={error.firstName}
                                        type="text"
                                        color="secondary"
                                        size="medium"
                                        placeholder="Enter first name"
                                        onChange={handleUserChange}
                                    />

                                    <TextField
                                        label="Last Name"
                                        fullWidth={true}
                                        name="lastName"
                                        required={true}
                                        variant="outlined"
                                        value={lastName}
                                        error={Boolean(error.lastName)}
                                        helperText={error.lastName}
                                        type="text"
                                        size="medium"
                                        color="secondary"
                                        placeholder="Enter last name"
                                        onChange={handleUserChange}
                                    />

                                    <TextField
                                        label="Email"
                                        fullWidth={true}
                                        name="email"
                                        required={true}
                                        variant="outlined"
                                        value={email}
                                        error={Boolean(error.email)}
                                        helperText={error.email}
                                        type="email"
                                        size="medium"
                                        color="secondary"
                                        placeholder="Enter email"
                                        onChange={handleUserChange}
                                    />

                                    <TextField
                                        label="Username"
                                        fullWidth={true}
                                        name="username"
                                        required={true}
                                        variant="outlined"
                                        value={username}
                                        error={Boolean(error.username)}
                                        helperText={error.username}
                                        type="text"
                                        color="secondary"
                                        size="medium"
                                        placeholder="Enter username"
                                        onChange={handleUserChange}
                                    />


                                    <TextField
                                        label="Phone"
                                        fullWidth={true}
                                        name="phoneNumber"
                                        required={true}
                                        variant="outlined"
                                        value={phoneNumber}
                                        error={Boolean(error.phoneNumber)}
                                        helperText={error.phoneNumber}
                                        type="tel"
                                        color="secondary"
                                        size="medium"
                                        placeholder="Enter phone number"
                                        onChange={handleUserChange}
                                    />

                                    <TextField
                                        label="Emergency Phone"
                                        fullWidth={true}
                                        name="emergencyPhone"
                                        required={true}
                                        variant="outlined"
                                        color="secondary"
                                        value={emergencyPhoneNumber}
                                        error={Boolean(error.emergencyPhoneNumber)}
                                        helperText={error.emergencyPhoneNumber}
                                        type="tel"
                                        size="medium"
                                        onChange={handleUserChange}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Card elevation={0}>
                            {authLoading && <LinearProgress variant="query" color="primary"/>}
                            <CardContent>
                                {
                                    authError && (
                                        <Alert sx={{mb: 2}} severity="error">
                                            <AlertTitle>{authError}</AlertTitle>
                                        </Alert>
                                    )
                                }
                                <Stack spacing={2} direction="column">
                                    <Typography variant="h6">
                                        Address Information
                                    </Typography>
                                    <TextField
                                        label="Country"
                                        fullWidth={true}
                                        name="country"
                                        required={true}
                                        variant="outlined"
                                        value={authData && country}
                                        error={Boolean(error.country)}
                                        helperText={error.country}
                                        type="text"
                                        color="secondary"
                                        size="medium"
                                        placeholder="Enter country"
                                        onChange={handleUserChange}
                                    />

                                    <TextField
                                        label="Region"
                                        fullWidth={true}
                                        name="region"
                                        required={true}
                                        variant="outlined"
                                        value={region}
                                        error={Boolean(error.region)}
                                        helperText={error.region}
                                        type="text"
                                        size="medium"
                                        color="secondary"
                                        placeholder="Enter region"
                                        onChange={handleUserChange}
                                    />

                                    <TextField
                                        label="City"
                                        fullWidth={true}
                                        name="city"
                                        required={true}
                                        variant="outlined"
                                        value={city}
                                        error={Boolean(error.city)}
                                        helperText={error.city}
                                        type="text"
                                        size="medium"
                                        color="secondary"
                                        placeholder="Enter city"
                                        onChange={handleUserChange}
                                    />

                                    <TextField
                                        label="Address Line 1"
                                        fullWidth={true}
                                        name="addressLine1"
                                        required={true}
                                        variant="outlined"
                                        value={addressLine1}
                                        error={Boolean(error.addressLine1)}
                                        helperText={error.addressLine1}
                                        type="text"
                                        color="secondary"
                                        size="medium"
                                        placeholder="Enter address line 1"
                                        onChange={handleUserChange}
                                        multiline={true}
                                        minRows={2.5}
                                    />


                                    <TextField
                                        label="Address Line 2"
                                        fullWidth={true}
                                        name="addressLine2"
                                        required={true}
                                        variant="outlined"
                                        value={addressLine2}
                                        error={Boolean(error.addressLine2)}
                                        helperText={error.addressLine2}
                                        type="tel"
                                        color="secondary"
                                        size="medium"
                                        placeholder="Enter address line 2"
                                        onChange={handleUserChange}
                                        multiline={true}
                                        minRows={2.5}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Layout>)
}

export default SettingsPage;
