import {Box, Typography} from "@mui/material";

const FAQ = ({faq}) => {
    return (
        <Box>
            <Typography mb={2} variant="body1" sx={{color: 'secondary.main', fontWeight: 700}}>
                {faq.question}
            </Typography>
            <Typography variant="body2" sx={{color: 'text.primary'}}>
                {faq.answer}
            </Typography>
        </Box>
    )
}

export default FAQ;
