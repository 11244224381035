import delivery from './../../assets/images/delivery.png';
import support from './../../assets/images/online-support.png';
import decrease from './../../assets/images/decrease.png';
import badge from './../../assets/images/badge.png';

export const choices = [
    {
        title: 'Fast Delivery',
        image: delivery,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae'
    },
    {
        title: '24/7 Support',
        image: support,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae'
    },
    {
        title: 'Affordable',
        image: decrease,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae'
    },
    {
        title: 'Quality',
        image: badge,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae'
    },
]
