import image from "./../../assets/images/airmax.jpg";
import shoe1 from "./../../assets/images/shoe1.jpg";
import shoe2 from "./../../assets/images/shoe2.jpg";
import shoe3 from "./../../assets/images/shoe3.jpg";

export const galleryItems = [
    {
        image: shoe1,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe3,
        name: 'Jordan',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe1,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe2,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe3,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe1,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe2,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe1,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe2,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    },
    {
        image: shoe3,
        name: 'Air Max',
        description: 'Rooted in sporty athletics DNA, the Air Max Dawn is thoughtfully made with at least 20% recycled materials by weight.',
        brand: 'Nike',
        price: 500
    }
]
