import {Box, Typography} from "@mui/material";
import Layout from "../../components/layout/layout";

const WishListsPage = () => {
    return (
        <Layout>
            <Box sx={{my: 6}}>
                <Typography variant="h4">Ready or Not</Typography>
            </Box>
        </Layout>
    )
}

export default WishListsPage;
