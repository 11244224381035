import {Button, Grid, Menu, MenuItem, Stack, Toolbar, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {
    DarkMode,
    ExitToApp,
    Favorite,
    Grid4x4,
    LightMode,
    LockOutlined,
    Menu as MenuIcon,
    MoreHoriz,
    ShoppingCart
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import UI_ACTION_CREATORS from "../../redux/ui/ui-action-creators";
import {selectUI} from "../../redux/ui/ui-reducer";
import {selectAuth} from "../../redux/auth/auth-reducer";
import {useState} from "react";
import Profile from "../shared/profile";
import SidenavLink from "../shared/side-nav-link";
import {AUTH_ACTION_CREATORS} from "../../redux/auth/auth-action-creators";

const MobileHeader = () => {
    const dispatch = useDispatch();
    const {themeVariant, activePath} = useSelector(selectUI);
    const {token, authData} = useSelector(selectAuth);

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = event => {
        setMenuOpen(true);
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenuOpen(false);
        setAnchorEl(null);
    }

    return (
        <Toolbar variant="regular">
            <Grid container={true} justifyContent="space-between" alignItems="center">
                <Grid item={true}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <MenuIcon
                            sx={{
                                padding: 0.4,
                                borderRadius: 1.5,
                                color: 'secondary.main',
                                cursor: 'pointer',
                                backgroundColor: 'light.secondary'
                            }}
                            onClick={() => dispatch(UI_ACTION_CREATORS.openDrawer())}
                        />
                        <Link to="/" style={{textDecoration: 'none'}}>
                            <Typography
                                sx={{color: 'secondary.main', fontWeight: 700}}
                                variant="h6">
                                Smash Sneakers
                            </Typography>
                        </Link>
                    </Stack>
                </Grid>
                <Grid item={true}>
                    <Grid item={true}>
                        {token ? (
                            <Stack direction="row" spacing={2} alignItems="center">
                                <ShoppingCart
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        borderRadius: 0.5,
                                        padding: 0.5,
                                        color: 'secondary.main',
                                        fontSize: 24
                                    }}
                                    fontSize="small"/>
                                <MoreHoriz
                                    onClick={handleMenuClick}
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        borderRadius: 0.5,
                                        padding: 0.5,
                                        color: 'secondary.main',
                                        fontSize: 24
                                    }}
                                    fontSize="small"/>
                                {themeVariant === 'light' ? (
                                    <DarkMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            color: 'secondary.main',
                                            cursor: 'pointer',
                                            backgroundColor: 'light.secondary',
                                            fontSize: 24
                                        }}
                                    />
                                ) : (
                                    <LightMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            backgroundColor: 'light.secondary',
                                            cursor: 'pointer',
                                            color: 'secondary.main',
                                            fontSize: 24
                                        }}
                                    />
                                )}
                            </Stack>
                        ) : (
                            <Stack direction="row" spacing={2} alignItems="center">
                                {themeVariant === 'light' ? (
                                    <DarkMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            color: 'secondary.main',
                                            cursor: 'pointer',
                                            backgroundColor: 'light.secondary',
                                            fontSize: 24
                                        }}
                                    />
                                ) : (
                                    <LightMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            backgroundColor: 'light.secondary',
                                            cursor: 'pointer',
                                            color: 'secondary.main',
                                            fontSize: 24
                                        }}
                                    />
                                )}
                            </Stack>
                        )}

                        <Menu
                            anchorEl={anchorEl}
                            elevation={1}
                            onClose={handleMenuClose}
                            open={menuOpen}>
                            <MenuItem>
                                <Stack spacing={1} direction="column">
                                    <Profile
                                        lastName={authData.lastName}
                                        firstName={authData.firstName}
                                        image={authData.image}
                                    />
                                </Stack>
                            </MenuItem>
                            <MenuItem>
                                <SidenavLink
                                    icon={
                                        <Grid4x4
                                            sx={{
                                                textTransform: 'capitalize',
                                                color: activePath === '/orders' ? 'secondary.main' : 'text.secondary',
                                                backgroundColor: activePath === '/orders' ? 'light.secondary' : false,
                                                borderRadius: 1.2,
                                                padding: 0.3,
                                                cursor: 'pointer'
                                            }}/>}
                                    path="/orders"
                                    label="Orders"
                                    active={activePath === '/orders'}
                                />
                            </MenuItem>
                            <MenuItem>
                                <SidenavLink
                                    icon={
                                        <Favorite
                                            sx={{
                                                textTransform: 'capitalize',
                                                color: activePath === '/wishlist' ? 'secondary.main' : 'text.secondary',
                                                backgroundColor: activePath === '/wishlist' ? 'light.secondary' : false,
                                                borderRadius: 1.2,
                                                padding: 0.3,
                                                cursor: 'pointer'
                                            }}/>}
                                    path="/wishlist"
                                    label="Wishlist"
                                    active={activePath === '/wishlist'}
                                />
                            </MenuItem>
                            <MenuItem>
                                <SidenavLink
                                    icon={
                                        <LockOutlined
                                            sx={{
                                                textTransform: 'capitalize',
                                                color: activePath === '/change-password' ? 'secondary.main' : 'text.secondary',
                                                backgroundColor: activePath === '/change-password' ? 'light.secondary' : false,
                                                borderRadius: 1.2,
                                                padding: 0.3,
                                                cursor: 'pointer'
                                            }}/>}
                                    path="/change-password"
                                    label="Change Password"
                                    active={activePath === '/change-password'}
                                />
                            </MenuItem>
                            <MenuItem>
                                <Button
                                    fullWidth={true}
                                    color="secondary"
                                    variant="text"
                                    onClick={() => dispatch(AUTH_ACTION_CREATORS.logout(token))}
                                    size="large"
                                    sx={{textTransform: 'capitalize', justifyContent: 'flex-start'}}
                                    startIcon={
                                        <ExitToApp
                                            sx={{
                                                textTransform: 'capitalize',
                                                backgroundColor: 'light.secondary',
                                                padding: 0.3,
                                                cursor: 'pointer'
                                            }}/>}>
                                    Logout
                                </Button>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        </Toolbar>
    )
}
export default MobileHeader;
