const SMASH_SNEAKERS_THEME_VARIANT_KEY = 'SMASH_SNEAKERS_THEME_VARIANT_KEY';
const SMASH_SNEAKERS_AUTH_TOKEN_KEY = 'SMASH_SNEAKERS_AUTH_TOKEN_KEY';
const SMASH_SNEAKERS_AUTH_DATA_KEY = 'SMASH_SNEAKERS_AUTH_DATA_KEY';
const BASE_SERVER_URL = 'http://localhost:6000/api/v1/user';

export const SMASH_SNEAKERS_CONSTANTS = {
    SMASH_SNEAKERS_THEME_VARIANT_KEY,
    SMASH_SNEAKERS_AUTH_TOKEN_KEY,
    SMASH_SNEAKERS_AUTH_DATA_KEY,
    BASE_SERVER_URL
};
