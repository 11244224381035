import {Box, Grid} from "@mui/material";
import Layout from "../../components/layout/layout";
import {useSelector} from "react-redux";
import {selectGalleryItem} from "../../redux/gallery/gallery-reducer";
import GalleryImage from "../../components/shared/gallery-image";

const GalleryPage = () => {

    const {galleryItems} = useSelector(selectGalleryItem);

    return (
        <Layout>
            <Box sx={{pt: 8, minHeight: '95vh'}}>
                <Grid container={true}>
                    {galleryItems && galleryItems.map((galleryItem, index) => {
                        return (
                            <Grid key={index} xs={12} md={4} lg={3} item={true}>
                                <GalleryImage variant="outlined" galleryImage={galleryItem}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Layout>
    )
}

export default GalleryPage;
