import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import UI_ACTION_CREATORS from "../../redux/ui/ui-action-creators";
import {Stack, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";

const SidenavLink = ({path, active, label, icon}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        navigate(path);
        dispatch(UI_ACTION_CREATORS.changeURL(path));
        dispatch(UI_ACTION_CREATORS.closeDrawer());
    }

    return (
        <Link
            to={path}
            onClick={handleClick}
            style={{
                textDecoration: 'none',
                width: '100%',
                display: 'block'
            }}>
            <Stack
                sx={{width: '100%'}}
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center">
                    {icon}
                    <Typography
                        variant="body2"
                        sx={{
                            textTransform: 'capitalize',
                            color: active ? 'secondary.main' : 'text.secondary',

                        }}>
                        {label}
                    </Typography>
                </Stack>
                <ChevronRight sx={{color: 'secondary.main'}}/>
            </Stack>
        </Link>
    )
}

export default SidenavLink;
