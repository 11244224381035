import {Container, Toolbar} from "@mui/material";

const TabletHeader = () => {
    return (
        <Toolbar>
            <Container>

            </Container>
        </Toolbar>
    )
}
export default TabletHeader;
