import {Card, CardContent, Stack, Typography} from "@mui/material";

const Choice = ({choice, elevation}) => {
    return (
        <Card elevation={elevation} variant="outlined"
              sx={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 3,
                  borderBottomColor: 'secondary.main',
                  backgroundColor: 'default.paper'
        }}>
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <img src={choice.image} alt={choice.title}/>
                    </Stack>
                    <Typography variant="h6" align="center" sx={{color: 'secondary.main'}}>
                        {choice.title}
                    </Typography>
                    <Typography variant="body2" align="center" sx={{color: 'text.primary'}}>
                        {choice.description}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Choice;
