import {Card, CardMedia, Stack, useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";

const Brand = ({brand}) => {

    const theme = useTheme();

    return (
        <Card variant="outlined"
              sx={{
                  borderRadius: 0,
                  borderWidth: 0.1,
                  borderStyle: 'solid',
                  borderColor: theme.palette.mode === 'light' ? grey[100] : grey[800],
                  backgroundColor: 'transparent',
                  padding: 0.5
              }}>
            <Stack direction="row" justifyContent="center">
                <CardMedia
                    component="img"
                    src={brand.image}
                    sx={{
                        objectPosition: 'center',
                        objectFit: 'contain',
                        height: 150,
                        width: 150
                    }}
                />
            </Stack>
        </Card>
    )
}

export default Brand;
