import {CHOICE_ACTION_TYPES} from "./choice-action-types";
import {choices} from "./data";

const INITIAL_STATE = {
    choices: [...choices],
    choiceLoading: false,
    choiceError: null,
    choiceMessage: null
};


const choiceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHOICE_ACTION_TYPES.GET_CHOICES_REQUEST:
            return {
                ...state,
                choiceError: null,
                choiceMessage: null,
                choiceLoading: true
            }

        case CHOICE_ACTION_TYPES.GET_CHOICES_SUCCESS:
            return {
                ...state,
                choiceError: null,
                choiceMessage: null,
                choiceLoading: true,
                choices: action.payload
            }

        case CHOICE_ACTION_TYPES.GET_CHOICES_FAIL:
            return {
                ...state,
                choiceError: null,
                choiceMessage: null,
                choiceLoading: true
            }
        default:
            return state;
    }
}

export const selectChoice = state => state.choice;

export default choiceReducer;
