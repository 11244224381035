import image from "./../../assets/images/profile.jpg";

export const testimonials = [
    {
        user: {
            name: 'Stanley Hayford',
        },
        text: 'Quality american brands at affordable prices. Beautiful stuff',
        rating: 5,
        updatedAt: new Date(2020, 9, 24)
    },
    {
        user: {
            name: 'Usermaatre Setepenre',
            image,
        },
        text: "You don't want to miss out on these cool footwear. Pamper your feet!",
        rating: 4.5,
        updatedAt: new Date(2020, 8, 24)
    },
    {
        user: {
            name: 'Zeus',
            image,
        },
        text: "Breathless. I found my favorite shop. Impressive",
        rating: 5,
        updatedAt: new Date(2020, 8, 25)
    },
    {
        user: {
            name: 'Stanley Hayford',
        },
        text: 'Quality american brands at affordable prices. Beautiful stuff',
        rating: 5,
        updatedAt: new Date(2020, 9, 24)
    },
    {
        user: {
            name: 'Usermaatre Setepenre',
            image,
        },
        text: "You don't want to miss out on these cool footwear. Pamper your feet!",
        rating: 4.5,
        updatedAt: new Date(2020, 8, 24)
    },
    {
        user: {
            name: 'Zeus',
            image,
        },
        text: "Breathless. I found my favorite shop. Impressive",
        rating: 5,
        updatedAt: new Date(2020, 8, 25)
    },
    {
        user: {
            name: 'Usermaatre Setepenre',
            image,
        },
        text: "You don't want to miss out on these cool footwear. Pamper your feet!",
        rating: 4.5,
        updatedAt: new Date(2020, 8, 24)
    },
    {
        user: {
            name: 'Zeus',
            image,
        },
        text: "Breathless. I found my favorite shop. Impressive",
        rating: 5,
        updatedAt: new Date(2020, 8, 25)
    }
]
