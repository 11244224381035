import {PRODUCT_ACTION_TYPES} from "./product-action-types";
import {bestSellers, featuredProducts, newArrivals, onSaleProducts, products} from "./data";

const INITIAL_STATE = {
    products: [...products],
    bestSellers: [...bestSellers],
    newArrivals: [...newArrivals],
    featuredProducts: [...featuredProducts],
    onSaleProducts: [...onSaleProducts],
    productLoading: false,
    productError: null,
    productMessage: null,
    totalProducts: 0,
    productDetail: {...products[0]}
};


const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_ACTION_TYPES.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                productError: null,
                productMessage: null,
                productLoading: true
            }

        case PRODUCT_ACTION_TYPES.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                productError: null,
                productMessage: null,
                productLoading: true,
                products: action.payload.data,
                totalProducts: action.payload.count
            }

        case PRODUCT_ACTION_TYPES.GET_PRODUCTS_FAIL:
            return {
                ...state,
                productError: null,
                productMessage: null,
                productLoading: true
            }


        case PRODUCT_ACTION_TYPES.GET_PRODUCT_REQUEST:
            return {
                ...state,
                productError: null,
                productMessage: null,
                productLoading: true,
                productDetail: null
            }

        case PRODUCT_ACTION_TYPES.GET_PRODUCT_SUCCESS:
            return {
                ...state,
                productError: null,
                productMessage: null,
                productLoading: true,
                productDetail: action.payload.data,
            }

        case PRODUCT_ACTION_TYPES.GET_PRODUCT_FAIL:
            return {
                ...state,
                productError: null,
                productMessage: null,
                productLoading: true
            }
        default:
            return state;
    }
}

export const selectProduct = state => state.product;

export default productReducer;
