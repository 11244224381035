import {GALLERY_ITEM_ACTION_TYPES} from "./gallery-action-types";
import {galleryItems} from "./data";

const INITIAL_STATE = {
    galleryItems: [...galleryItems],
    galleryItemLoading: false,
    galleryItemError: null,
    galleryItemMessage: null,
    totalFAQs: 0
};


const galleryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GALLERY_ITEM_ACTION_TYPES.GET_GALLERY_ITEMS_REQUEST:
            return {
                ...state,
                galleryItemError: null,
                galleryItemMessage: null,
                galleryItemLoading: true
            }

        case GALLERY_ITEM_ACTION_TYPES.GET_GALLERY_ITEMS_SUCCESS:
            return {
                ...state,
                galleryItemError: null,
                galleryItemMessage: null,
                galleryItemLoading: true,
                galleryItems: action.payload.data,
                totalFAQs: action.payload.count
            }

        case GALLERY_ITEM_ACTION_TYPES.GET_GALLERY_ITEMS_FAIL:
            return {
                ...state,
                galleryItemError: null,
                galleryItemMessage: null,
                galleryItemLoading: true
            }
        default:
            return state;
    }
}

export const selectGalleryItem = state => state.galleryItem;

export default galleryReducer;
