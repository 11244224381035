import {createTheme} from "@mui/material/styles";

const lightTheme = createTheme({
    components: {},
    typography: {
        fontFamily: 'EuclidCircularB'
    },
    palette: {
        mode: 'light',
        background: {
            default: '#F0F2F5',
            paper: '#FFFFFF'
        },
        text: {
            secondary: '#6f7176',
            primary: '#5e5f5f'
        },
        primary: {
            main: '#FFFFFF'
        },
        secondary: {
            main: '#cd3d61'
        },
        light: {
            secondary: 'rgba(210,70,98,0.15)',
            primary: '#f4f5f7'
        },
        action: {
            active: '#d24662'
        },
        link: {
            primary: '',
            secondary: ''
        }
    },
    shape: {
        borderRadius: 4,
    }
});

const darkTheme = createTheme({
    components: {},
    typography: {
        fontFamily: 'EuclidCircularB'
    },
    palette: {
        mode: 'dark',
        background: {
            default: '#181b1f',
            paper: '#21242F'
        },
        text: {
            primary: '#f2f2f4',
            secondary: '#6f7176'
        },
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#d24662'
        },
        light: {
            secondary: 'rgba(210,70,98,0.15)',
            primary: '#0000004C'
        },
        action: {
            active: '#d24662'
        }
    },
    shape: {
        borderRadius: 4
    }
});

export const THEMES = {lightTheme, darkTheme};
