import {UI_ACTION_TYPES} from "./ui-action-types";
import {SMASH_SNEAKERS_CONSTANTS} from "../../constants/constants";

const INITIAL_STATE = {
    themeVariant: 'light',
    drawerOpen: false,
    activePath: '/'
};


const uiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UI_ACTION_TYPES.TOGGLE_THEME:
            localStorage.setItem(
                SMASH_SNEAKERS_CONSTANTS.SMASH_SNEAKERS_THEME_VARIANT_KEY,
                state.themeVariant === 'light' ? 'dark' : 'light'
            )
            return {
                ...state,
                themeVariant: state.themeVariant === 'light' ? 'dark' : 'light'
            }

        case UI_ACTION_TYPES.CLOSE_DRAWER:
            return {
                ...state,
                drawerOpen: false
            }

        case UI_ACTION_TYPES.OPEN_DRAWER:
            return {
                ...state,
                drawerOpen: true
            }

        case UI_ACTION_TYPES.CHANGE_ACTIVE_PATH:
            return {
                ...state,
                activePath: action.payload
            }

        default:
            return state;
    }
}

export const selectUI = state => state.ui;

export default uiReducer;
