import {CONTACT_ACTION_TYPES} from "./contact-action-types";

const INITIAL_STATE = {
    contactLoading: false,
    contactError: null,
    contactMessage: null
};


const contactReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONTACT_ACTION_TYPES.CREATE_CONTACT_REQUEST:
            return {
                ...state,
                contactError: null,
                contactMessage: null,
                contactLoading: true
            }

        case CONTACT_ACTION_TYPES.CREATE_CONTACT_SUCCESS:
            return {
                ...state,
                contactError: null,
                contactMessage: null,
                contactLoading: true,
                contacts: action.payload
            }

        case CONTACT_ACTION_TYPES.CREATE_CONTACT_FAIL:
            return {
                ...state,
                contactError: null,
                contactMessage: null,
                contactLoading: true
            }
        default:
            return state;
    }
}

export const selectContact = state => state.contact;

export default contactReducer;
