export const faqs = [
    {
        question: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        answer: ' Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis'
    },
    {
        question: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        answer: ' Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis'
    },
    {
        question: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        answer: ' Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis'
    },
    {
        question: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        answer: ' Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis'
    },
    {
        question: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        answer: ' Maxime mollitia molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis'
    }
]
