import {Badge, Button, Container, Grid, Menu, MenuItem, Stack, Toolbar, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import NavLink from "../shared/nav-link";
import {useDispatch, useSelector} from "react-redux";
import {selectUI} from "../../redux/ui/ui-reducer";
import {selectAuth} from "../../redux/auth/auth-reducer";
import {
    DarkMode,
    ExitToApp,
    Favorite,
    FavoriteBorder,
    Grid4x4,
    LightMode,
    LockOutlined,
    MoreHoriz,
    ShoppingCart
} from "@mui/icons-material";
import UI_ACTION_CREATORS from "../../redux/ui/ui-action-creators";
import Profile from "../shared/profile";
import SidenavLink from "../shared/side-nav-link";
import {AUTH_ACTION_CREATORS} from "../../redux/auth/auth-action-creators";
import {useState} from "react";

const DesktopHeader = () => {
    const {activePath, themeVariant} = useSelector(selectUI);
    const {token, authData} = useSelector(selectAuth);
    const dispatch = useDispatch();

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = event => {
        setMenuOpen(true);
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenuOpen(false);
        setAnchorEl(null);
    }
    return (
        <Toolbar variant="regular">
            <Container>
                <Grid container={true} alignItems="center" justifyContent="space-between">
                    <Grid item={true}>
                        <Link to="/" style={{textDecoration: 'none'}}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography
                                    sx={{color: 'secondary.main', fontWeight: 700}}
                                    variant="h4">
                                    Smash Sneakers
                                </Typography>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item={true}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <NavLink path="/" label="Home" active={activePath === '/'}/>
                            <NavLink path="/products" label="Sneakers" active={activePath === '/products'}/>
                            <NavLink path="/gallery" label="Gallery" active={activePath === '/gallery'}/>
                            <NavLink path="/about" label="About" active={activePath === '/about'}/>
                            <NavLink path="/contact" label="Contact" active={activePath === '/contact'}/>
                        </Stack>
                    </Grid>
                    <Grid item={true}>
                        {token ? (
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Profile
                                    image={authData.image}
                                    firstName={authData.firstName}
                                    lastName={authData.lastName}
                                />
                                <Badge badgeContent={88} variant="dot">

                                    <FavoriteBorder
                                        sx={{
                                            backgroundColor: 'light.secondary',
                                            padding: 0.5,
                                            color: 'secondary.main',
                                            fontSize: 28,
                                            cursor: 'pointer'
                                        }}
                                        fontSize="small"/>
                                </Badge>

                                <Badge badgeContent={88} variant="dot">
                                    <ShoppingCart
                                        sx={{
                                            backgroundColor: 'light.secondary',
                                            borderRadius: 0.5,
                                            padding: 0.5,
                                            color: 'secondary.main',
                                            fontSize: 28,
                                            cursor: 'pointer'
                                        }}
                                        fontSize="small"/>
                                </Badge>
                                <MoreHoriz
                                    onClick={handleMenuClick}
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        borderRadius: 0.5,
                                        padding: 0.5,
                                        color: 'secondary.main',
                                        fontSize: 28,
                                        cursor: 'pointer'
                                    }}
                                    fontSize="small"/>
                                {themeVariant === 'light' ? (
                                    <DarkMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            color: 'secondary.main',
                                            cursor: 'pointer',
                                            backgroundColor: 'light.secondary',
                                            fontSize: 28
                                        }}
                                    />
                                ) : (
                                    <LightMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            backgroundColor: 'light.secondary',
                                            cursor: 'pointer',
                                            color: 'secondary.main',
                                            fontSize: 28
                                        }}
                                    />
                                )}
                            </Stack>
                        ) : (
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Link to="/auth/login" style={{textDecoration: 'none'}}>
                                    <Button
                                        color="secondary"
                                        size="large"
                                        variant="outlined"
                                        disableElevation={true}>
                                        Sign In
                                    </Button>
                                </Link>
                                <Link to="/auth/register" style={{textDecoration: 'none'}}>
                                    <Button
                                        color="secondary"
                                        size="large"
                                        variant="contained"
                                        disableElevation={true}>
                                        Sign Up
                                    </Button>
                                </Link>
                                {themeVariant === 'light' ? (
                                    <DarkMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            color: 'secondary.main',
                                            cursor: 'pointer',
                                            backgroundColor: 'light.secondary',
                                            fontSize: 28
                                        }}
                                    />
                                ) : (
                                    <LightMode
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                        sx={{
                                            padding: 0.4,
                                            borderRadius: 1.5,
                                            backgroundColor: 'light.secondary',
                                            cursor: 'pointer',
                                            color: 'secondary.main',
                                            fontSize: 28
                                        }}
                                    />
                                )}
                            </Stack>
                        )}
                    </Grid>
                </Grid>

                <Menu
                    anchorEl={anchorEl}
                    elevation={1}
                    onClose={handleMenuClose}
                    open={menuOpen}>
                    <MenuItem>
                        <Stack spacing={1} direction="column">
                            <Profile
                                lastName={authData.lastName}
                                firstName={authData.firstName}
                                image={authData.image}
                            />
                        </Stack>
                    </MenuItem>
                    <MenuItem>
                        <SidenavLink
                            icon={
                                <Grid4x4
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/orders' ? 'secondary.main' : 'text.secondary',
                                        backgroundColor: activePath === '/orders' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/orders"
                            label="Orders"
                            active={activePath === '/orders'}
                        />
                    </MenuItem>
                    <MenuItem>
                        <SidenavLink
                            icon={
                                <Favorite
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/wishlist' ? 'secondary.main' : 'text.secondary',
                                        backgroundColor: activePath === '/wishlist' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/wishlist"
                            label="Wishlist"
                            active={activePath === '/wishlist'}
                        />
                    </MenuItem>
                    <MenuItem>
                        <SidenavLink
                            icon={
                                <LockOutlined
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/change-password' ? 'secondary.main' : 'text.secondary',
                                        backgroundColor: activePath === '/change-password' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/change-password"
                            label="Change Password"
                            active={activePath === '/change-password'}
                        />
                    </MenuItem>
                    <MenuItem>
                        <Button
                            fullWidth={true}
                            color="secondary"
                            variant="text"
                            onClick={() => dispatch(AUTH_ACTION_CREATORS.logout(token))}
                            size="large"
                            sx={{textTransform: 'capitalize', justifyContent: 'flex-start'}}
                            startIcon={
                                <ExitToApp
                                    sx={{
                                        textTransform: 'capitalize',
                                        backgroundColor: 'light.secondary',
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}>
                            Logout
                        </Button>
                    </MenuItem>
                </Menu>
            </Container>
        </Toolbar>
    )
}
export default DesktopHeader;
