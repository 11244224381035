import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import {
    BrowseGalleryRounded,
    Close,
    Contacts,
    DarkMode, Face, FavoriteBorder, Grid4x4,
    HomeRounded,
    InfoRounded,
    LightMode,
    Shop2Rounded, ShoppingCart
} from "@mui/icons-material";
import UI_ACTION_CREATORS from "../../redux/ui/ui-action-creators";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {selectAuth} from "../../redux/auth/auth-reducer";
import {selectUI} from "../../redux/ui/ui-reducer";
import SidenavLink from "../shared/side-nav-link";

const MobileSideDrawer = () => {
    const dispatch = useDispatch();
    const {token} = useSelector(selectAuth);
    const {themeVariant, activePath} = useSelector(selectUI);

    return (
        <Box
            sx={{
                minWidth: {
                    xs: '90vw', md: '40vw', lg: '30vw',
                    backgroundColor: 'background.default',
                    minHeight: '100vh',
                    py: 2
                }
            }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{px: 2, py: 2}}>
                {themeVariant === 'light' ? (
                    <DarkMode
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                        sx={{
                            padding: 0.4,
                            borderRadius: 1.5,
                            color: 'secondary.main',
                            cursor: 'pointer',
                            backgroundColor: 'light.secondary'
                        }}
                    />
                ) : (
                    <LightMode
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                        sx={{
                            padding: 0.4,
                            borderRadius: 1.5,
                            color: 'secondary.main',
                            cursor: 'pointer',
                            backgroundColor: 'light.secondary'
                        }}
                    />
                )}
                <Close
                    onClick={() => dispatch(UI_ACTION_CREATORS.closeDrawer())}
                    sx={{
                        padding: 0.4,
                        borderRadius: 1.5,
                        color: 'secondary.main',
                        cursor: 'pointer',
                        backgroundColor: 'light.secondary'
                    }}
                />
            </Stack>

            <Stack sx={{padding: 2}} direction="column" spacing={3}>
                <Box>
                    <Link to="/" style={{textDecoration: 'none'}}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography
                                sx={{color: 'secondary.main', fontWeight: 700}}
                                variant="h4">
                                Smash Sneakers
                            </Typography>
                        </Stack>
                    </Link>
                </Box>
                <Box>
                    <Stack direction="column" spacing={2}>
                        <SidenavLink
                            icon={
                                <HomeRounded
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/' ? 'secondary.main' : 'text.secondary',
                                        borderColor: activePath === '/' ? 'secondary.main' : 'primary.light',
                                        borderWidth: 0.3,
                                        borderStyle: 'solid',
                                        backgroundColor: activePath === '/' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/"
                            label="Home"
                            active={activePath === '/'}
                        />
                        <SidenavLink
                            icon={
                                <Shop2Rounded
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/products' ? 'secondary.main' : 'text.secondary',
                                        borderColor: activePath === '/products' ? 'secondary.main' : 'primary.light',
                                        borderWidth: 0.3,
                                        borderStyle: 'solid',
                                        backgroundColor: activePath === '/products' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/products"
                            label="Sneakers"
                            active={activePath === '/products'}
                        />
                        <SidenavLink
                            icon={
                                <BrowseGalleryRounded
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/gallery' ? 'secondary.main' : 'text.secondary',
                                        borderColor: activePath === '/gallery' ? 'secondary.main' : 'primary.light',
                                        borderWidth: 0.3,
                                        borderStyle: 'solid',
                                        backgroundColor: activePath === '/gallery' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/gallery"
                            label="Gallery"
                            active={activePath === '/gallery'}
                        />
                        <SidenavLink
                            icon={
                                <InfoRounded
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/about' ? 'secondary.main' : 'text.secondary',
                                        borderColor: activePath === '/about' ? 'secondary.main' : 'primary.light',
                                        borderWidth: 0.3,
                                        borderStyle: 'solid',
                                        backgroundColor: activePath === '/about' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/about"
                            label="About"
                            active={activePath === '/about'}
                        />
                        <SidenavLink
                            icon={
                                <Contacts
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: activePath === '/contact' ? 'secondary.main' : 'text.secondary',
                                        borderColor: activePath === '/contact' ? 'secondary.main' : 'primary.light',
                                        borderWidth: 0.3,
                                        borderStyle: 'solid',
                                        backgroundColor: activePath === '/contact' ? 'light.secondary' : false,
                                        borderRadius: 1.2,
                                        padding: 0.3,
                                        cursor: 'pointer'
                                    }}/>}
                            path="/contact"
                            label="Contact"
                            active={activePath === '/contact'}
                        />
                    </Stack>
                </Box>

                <Divider variant="fullWidth" sx={{my: 2}} light={true} />

                <Box>
                    {token ? (
                        <Stack spacing={1} alignItems="center">
                            <SidenavLink
                                icon={
                                    <Face
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: activePath === '/profile' ? 'secondary.main' : 'text.secondary',
                                            borderColor: activePath === '/profile' ? 'secondary.main' : 'primary.light',
                                            borderWidth: 0.3,
                                            borderStyle: 'solid',
                                            backgroundColor: activePath === '/profile' ? 'light.secondary' : false,
                                            borderRadius: 1.2,
                                            padding: 0.3,
                                            cursor: 'pointer'
                                        }}/>}
                                path="/profile"
                                label="Profile"
                                active={activePath === '/profile'}
                            />
                            <SidenavLink
                                icon={
                                    <ShoppingCart
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: activePath === '/cart' ? 'secondary.main' : 'text.secondary',
                                            borderColor: activePath === '/cart' ? 'secondary.main' : 'primary.light',
                                            borderWidth: 0.3,
                                            borderStyle: 'solid',
                                            backgroundColor: activePath === '/cart' ? 'light.secondary' : false,
                                            borderRadius: 1.2,
                                            padding: 0.3,
                                            cursor: 'pointer'
                                        }}/>}
                                path="/cart"
                                label="Cart"
                                active={activePath === '/cart'}
                            />
                            <SidenavLink
                                icon={
                                    <Grid4x4
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: activePath === '/orders' ? 'secondary.main' : 'text.secondary',
                                            borderColor: activePath === '/orders' ? 'secondary.main' : 'primary.light',
                                            borderWidth: 0.3,
                                            borderStyle: 'solid',
                                            backgroundColor: activePath === '/orders' ? 'light.secondary' : false,
                                            borderRadius: 1.2,
                                            padding: 0.3,
                                            cursor: 'pointer'
                                        }}/>}
                                path="/orders"
                                label="Orders"
                                active={activePath === '/gallery'}
                            />
                            <SidenavLink
                                icon={
                                    <FavoriteBorder
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: activePath === '/wishlist' ? 'secondary.main' : 'text.secondary',
                                            borderColor: activePath === '/wishlist' ? 'secondary.main' : 'primary.light',
                                            borderWidth: 0.3,
                                            borderStyle: 'solid',
                                            backgroundColor: activePath === '/wishlist' ? 'light.secondary' : false,
                                            borderRadius: 1.2,
                                            padding: 0.3,
                                            cursor: 'pointer'
                                        }}/>}
                                path="/wishlist"
                                label="Wishlist"
                                active={activePath === '/wishlist'}
                            />

                        </Stack>
                    ) : (
                        <Stack direction="column" spacing={2}>
                            <Link to="/auth/login" style={{textDecoration: 'none'}}>
                                <Button
                                    fullWidth={true}
                                    color="secondary"
                                    size="large"
                                    variant="outlined"
                                    disableElevation={true}>
                                    Sign In
                                </Button>
                            </Link>
                            <Link to="/auth/register" style={{textDecoration: 'none'}}>
                                <Button
                                    fullWidth={true}
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                    disableElevation={true}>
                                    Sign Up
                                </Button>
                            </Link>
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Box>
    )
}

export default MobileSideDrawer;
