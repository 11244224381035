import {Avatar, Stack, Typography} from "@mui/material";

const Profile = ({firstName, lastName, image}) => {

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            {
                image ?
                    <Avatar src={image}/> :
                    <Avatar sx={{backgroundColor: "light.secondary"}}>
                        <Typography
                            sx={{color: 'secondary.main', fontWeight: 700}}
                            variant="h6">
                            {`${firstName[0]}${lastName[0]}`}
                        </Typography>
                    </Avatar>
            }
            <Typography sx={{color: 'text.primary'}} variant="body2">{`${firstName} ${lastName}`}</Typography>
        </Stack>
    )
}

export default Profile;
